import React, { Component } from 'react'
import NavigationContainers from '../../components/navigation-containers'

// Components Globals
import Header from 'components/header'
import Footer from 'components/footer/main'
import Demo from 'components/s-demo/main'
import BoxVideo from 'components/box-video'
import SEO from 'components/seo'

import { BannerConteudo, SectionVideos } from '../../Style/conteudos'
import { Container, SeloTitle } from '../../Style/global'

// Sass Global
import 'sass/main.scss'

class PageConteudos extends Component {
  render() {
    return (
      <div className="main">
        <SEO title="Vídeos" />

        <Header />

        <BannerConteudo>
          <Container className="cont">
            <SeloTitle>Conteúdos</SeloTitle>
            <h1>Conteúdos para descomplicar a gestão do seu negócio</h1>
            <NavigationContainers />
          </Container>
        </BannerConteudo>

        <SectionVideos>
          <Container>
            <div className="all">
              <BoxVideo
                url_video="https://youtu.be/oj4PsiX_U2c"
                img_video={`https://i.ytimg.com/vi/oj4PsiX_U2c/hqdefault.jpg`}
                titulo_video="Abrir uma Farmácia - Vantagens e Desvantagens"
                descricao_video="Confira nesse vídeo as vantagens e desvantagens de ABRIR uma farmácia do zero! Quais são os desafios e como se preparar para o início do seu investimento!"
              />
              <BoxVideo
                url_video="https://youtu.be/0GxzkLMKRNU"
                img_video={`https://i.ytimg.com/vi/0GxzkLMKRNU/hqdefault.jpg`}
                titulo_video="Dicas para Tratar a Inadimplência da Farmácia"
                descricao_video="A taxa de inadimplência infelizmente sempre existirá enquanto o crediário estiver aplicado na sua farmácia, mas podemos reduzi-la se tomarmos algumas iniciativas."
              />
              <BoxVideo
                url_video="https://youtu.be/sGV3jpjScH4"
                img_video={`https://i.ytimg.com/vi/sGV3jpjScH4/hqdefault.jpg`}
                titulo_video="Desorganização na Farmácia"
                descricao_video="Confira nesse vídeo os principais pontos que você deve se atentar para manter sua farmácia organizada e sem perder dinheiro."
              />
              <BoxVideo
                url_video="https://youtu.be/lXVAbAkNaPE"
                img_video={`https://i.ytimg.com/vi/lXVAbAkNaPE/hqdefault.jpg`}
                titulo_video="3 Pontos de Sabotagem do seu Plano Estratégico da Farmácia"
                descricao_video="Veja nesse vídeo 3 pontos apresentados pelo Rafael Almeida e como se livrar da sabotagem na hora de manter seu plano estratégico saudável e funcionando."
              />
              <BoxVideo
                url_video="https://youtu.be/tjNRXOil-ko"
                img_video={`https://i.ytimg.com/vi/tjNRXOil-ko/hqdefault.jpg`}
                titulo_video="Como Criar e Trabalhar Datas Comemorativas na Farmácia"
                descricao_video="Aproveita datas comemorativas para criar ações de marketing e lucrar cada vez mais com o seu negócio."
              />
              <BoxVideo
                url_video="https://youtu.be/_FBHDE2_ayc"
                img_video={`https://i.ytimg.com/vi/_FBHDE2_ayc/hqdefault.jpg`}
                titulo_video="3 Pontos Fundamentais para o Marketing na sua Farmácia"
                descricao_video="Antes de realizar qualquer ação de marketing na sua farmácia, siga essas 3 dicas do Rafael Almeida para que você tenha mais resultado."
              />
              <BoxVideo
                url_video="https://youtu.be/4uZZ-9yxkRU"
                img_video={`https://i.ytimg.com/vi/4uZZ-9yxkRU/hqdefault.jpg`}
                titulo_video="Técnicas para Melhorar suas Vendas"
                descricao_video="Neste vídeo, o consultor de treinamentos Rafael Almeida, deu dicas valiosas para você vender ainda mais na sua farmácia. "
              />
              <BoxVideo
                url_video="https://www.youtube.com/watch?v=SwNCOe63CNM"
                img_video={`https://i.ytimg.com/vi/SwNCOe63CNM/hqdefault.jpg`}
                titulo_video="3 Passos para fazer um EVENTO de SUCESSO na FARMÁCIA!"
                descricao_video="Neste vídeo, o consultor em gestão e marketing do varejo farmacêutico e parceiro do INOVAFARMA, Vitor Costa, falou um pouco com você sobre criar eventos na farmácia"
              />
              <BoxVideo
                url_video="https://youtu.be/T9Ydws87-MM"
                img_video={`https://i.ytimg.com/vi/T9Ydws87-MM/hqdefault.jpg`}
                titulo_video="3 Dicas para DIMINUIR as Rupturas de Estoque nas Vendas da Farmácia"
                descricao_video="Neste vídeo o especialista Rafael Almeida do INOVAFARMA, traz para você, três dicas para diminuir as rupturas de estoque na sua farmácia."
              />
              <BoxVideo
                url_video="https://www.youtube.com/watch?v=cLSyZfW0ddA"
                img_video={`https://i.ytimg.com/vi/cLSyZfW0ddA/hqdefault.jpg`}
                titulo_video="O que são Sangrias e Suprimentos de Caixa?"
                descricao_video="Nesse vídeo, Rafael Almeida explica o que são e como devem ser utilizadas essas funções na farmácia, facilitando a organização e segurança do setor financeiro."
              />
              <BoxVideo
                url_video="https://youtu.be/9lZpwNSgur0"
                img_video={`https://i.ytimg.com/vi/9lZpwNSgur0/hqdefault.jpg`}
                titulo_video="Como você faz a Gestão FINANCEIRA da sua Farmácia?"
                descricao_video="Nesse vídeo, o consultor farmacêutico Vitor Costa, nos explica sobre como criar e gerenciar um plano de contas para manter a sua farmácia organizada na questão financeira."
              />
              <BoxVideo
                url_video="https://youtu.be/sh_cWP4oK-k"
                img_video={`https://i.ytimg.com/vi/sh_cWP4oK-k/hqdefault.jpg`}
                titulo_video="Quais são os Tributos que você PAGA na Drogaria?"
                descricao_video="Nesse vídeo, Rafael Almeida nos apresenta os impostos que são pagos na drogaria/farmácia, como eles são gerados e para onde vão as arrecadações."
              />
              <BoxVideo
                url_video="https://youtu.be/bSXuqq_hNfI"
                img_video={`https://i.ytimg.com/vi/bSXuqq_hNfI/hqdefault.jpg`}
                titulo_video="Como Estruturar um BOM Plano de Contas na Farmácia?"
                descricao_video="Neste vídeo, Rafael Almeida nos apresenta algumas dicas para criarmos um bom Plano de Contas e melhorarmos a gestão financeira da farmácia."
              />
              <BoxVideo
                url_video="https://youtu.be/DX1Lscj1Ghg"
                img_video={`https://i.ytimg.com/vi/DX1Lscj1Ghg/hqdefault.jpg`}
                titulo_video="Por que a sua Farmácia não VENDE o quanto você gostaria?"
                descricao_video="Neste vídeo, o consultor em gestão e marketing do varejo farmacêutico e parceiro do INOVAFARMA, Vitor Costa, falou um pouco com você sobre metas e vendas na farmácia."
              />
              <BoxVideo
                url_video="https://youtu.be/IL69kWiZGm4"
                img_video={`https://i.ytimg.com/vi/IL69kWiZGm4/hqdefault.jpg`}
                titulo_video="Como você faz para Comprar MELHOR na sua Farmácia?"
                descricao_video="Confira neste vídeo, algumas dicas do especialista Rafael Almeida sobre procedimentos para melhorar as compras da sua farmácia."
              />
            </div>
          </Container>
        </SectionVideos>

        <Demo />

        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default PageConteudos
